import { useAppContext } from "../../context/AppContext";
import ListExperts from "./ListExperts";
import ListLiensUtiles from "./ListLiensUtiles";
import { VscTriangleUp } from "react-icons/vsc";
import { useRef, useState } from "react";
// import logoGris from "../../assets/oliverLogo_grisFonce-removebg-preview.png";
import logoGris from "../../assets/oliverLogo_grisFonce.png";
import { ReactComponent as ExpertIcon } from "../../assets/iconeExpert.svg";
import ReservationSection from "../chat_view/chat_message/ReservationSection"
import { IoIosInformationCircle } from "react-icons/io";
import ContactExpertInformationForm from "./ContactExpertInformationForm";

const RightPanel = ({ mobileMode = false, open = true, setOpen }) => {


  const {
    liensUtiles,
    experts,
    messages,
    writing,
    selectedOption,
  } = useAppContext();


  const mobilePanelRef = useRef(null);


  const [modalLoginOpen, setModalLoginOpen] = useState(false);
  const [modalReservationOpen, setModalReservationOpen] = useState(false);
  const [modalContactExpertInformationOpen, setModalContactExpertInformationOpen] = useState(false);
  const touchStartY = useRef(null);

  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    if (touchStartY.current === null) {
      return;
    }
    const touchEndX = e.touches[0].clientY;
    const deltaY = touchStartY.current - touchEndX;
    if (deltaY < -5 && mobilePanelRef.current.scrollTop == 0) {
      setOpen(false);
    }

    touchStartY.current = null;
  };

  const textTooltip = "Si vous fournissez des solutions (produits, services, prestations, etc.) aidant les entreprises dans leurs besoins administratifs ou juridiques, vous pouvez les référencer sur Oliver en cliquant sur le lien ci-dessous. Elles seront présentées de manière pertinente aux utilisateurs."
  return (
    <div
      // className={`dark:bg-dark-grey bg-slate-400 relative md:h-full
      className={`bg-dark-grey relative md:h-full
        ${mobileMode
          ? `${liensUtiles.length > 0 || experts.length > 0
            ? "block"
            : "hidden"
          } 
        ${open ? "h-full" : "h-[10%]"
          } border-t-4 border-neutral-600 duration-300 w-full relative md:hidden`
          : `hidden md:block md:w-[25vw]`
        }
        `}
      onTouchStart={(e) => {
        if (mobileMode && open) handleTouchStart(e);
      }}
      onTouchMove={(e) => {
        if (mobileMode && open) handleTouchMove(e);
      }}
    >
      <div className={`absolute z-0 w-full h-full flex items-center justify-center overflow-hidden ${(liensUtiles.length > 0 || experts.length > 0) && "hidden"} `}>
        <img src={logoGris} alt="" className="w-2/3 z-0" />
      </div>

      {mobileMode ? (
        <div className="h-full  overflow-y-auto" ref={mobilePanelRef}>
          <div
            className={`w-full flex justify-center absolute z-0 ${open ? "" : "top-[-14px]"
              }`}
          >
            <div className={"w-20 flex"} onClick={() => setOpen(!open)}>
              <VscTriangleUp
                className={`w-20 border bg-dark-grey border-neutral-600  flex items-center justify-center cursor-pointer text-neutral-600 ${open ? "rotate-180" : ""
                  }`}
              />
            </div>
          </div>
          {!open && (liensUtiles.length > 0 || experts.length > 0) && (
            <div
              onClick={() => setOpen(!open)}
              className="w-full h-full z-0 relative flex "
            >
              {experts.length > 0 && (
                <div className={`no-hidden flex items-center w-[65%] justify-center`}>
                  <div
                    className={`flex items-center justify-center h-8 w-8 rounded-full text-black bg-first-oliver`}
                  >
                    <span className=" font-medium">{experts.length}</span>
                  </div>
                  <div
                    className={`leading-5 w-[75%] pl-4 font-medium text-first-oliver`}
                  >
                    Experts pour vous accompagner
                  </div>
                </div>
              )}
              {liensUtiles.length > 0 && (
                <div className="flex items-center w-[35%] justify-center">
                  <div
                    className={` flex items-center justify-center  h-8 w-8 rounded-full text-black bg-first-oliver`}
                  >
                    <span className=" font-medium">{liensUtiles.length}</span>
                  </div>
                  <div
                    className={`leading-5 w-[70%] pl-4 font-medium text-first-oliver`}
                  >
                    Liens utiles
                  </div>
                </div>
              )}
            </div>
          )}

          {open && (
            <div className="relative overflow-x-hidden">
              <ListExperts experts={experts} />
              <ListLiensUtiles liensUtiles={liensUtiles} />
            </div>
          )}
        </div>
      ) : (
        <div className="h-[100%] flex flex-col">

          {/* {console.log("?????????????????? messages[messages.length-1].id:", messages[messages.length-1].id)} */}
          {messages && messages.length > 0 &&
            messages[messages.length - 1].id === -4 ?
            (
              <div className={`${writing && "hidden"} relative overflow-y-auto`}>
                <div className="leading-5	text-lg pl-4 my-4 font-medium text-first-oliver">
                  Formalités juridique de <strong>{selectedOption.action} {'>'} {selectedOption.titleFormalite} </strong>
                </div>
                <div className="leading-5  px-4 my-4">
                  <ReservationSection
                    modalLoginOpen={modalLoginOpen}
                    setModalLoginOpen={setModalLoginOpen}
                    modalReservationOpen={modalReservationOpen}
                    setModalReservationOpen={setModalReservationOpen}
                    writing={writing}
                  />
                </div>
              </div>

            ) : ""
          }

          <div className="relative overflow-x-hidden">
            <ListExperts experts={experts} />
            <ListLiensUtiles liensUtiles={liensUtiles} />
          </div>
          {/* Put the div at the bottomm */}
          <div className="w-full mt-auto flex items-center justify-between p-[0.4em] z-10 border-t border-neutral-600">
            <div className="flex items-center">
              <ExpertIcon className="w-12 h-10 mt-2" fill="#fff" />
              <h1 className="text-white cursor-pointer" onClick={() => { setModalContactExpertInformationOpen(true) }}>Me référencer en tant qu'expert</h1>
            </div>

            <div className="group relative flex items-center">
              <IoIosInformationCircle className="text-white rounded text-sm shadow-sm" size={30} />
              <span className="absolute bottom-full mb-3 right-0 max-w-[calc(100vw-3rem)] w-72 transform scale-0 rounded bg-white p-2 text-xs text-gray-800 group-hover:scale-100 transition duration-150 ease-in-out">
                ✨ {textTooltip}
              </span>
            </div>
          </div>






          <ContactExpertInformationForm modalContactExpertInformationOpen={modalContactExpertInformationOpen} setModalContactExpertInformationOpen={setModalContactExpertInformationOpen} />
        </div>
      )
      }


    </div >
  );
};

export default RightPanel;
